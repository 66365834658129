var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{on:{"click":function($event){return _vm.closeAllPopovers()}}},[_c('b-table-simple',{staticClass:"mt-3",attrs:{"bordered":"","small":"","foot-clone":""}},[_c('b-thead',[_c('b-tr',[_c('b-th',{staticClass:"text-center align-middle",attrs:{"rowspan":"2"}},[_vm._v("Nivel Cognitivo")]),_c('b-th',{staticClass:"text-center align-middle th-custom",attrs:{"rowspan":"2"}},[_vm._v("Cantidad de "+_vm._s(_vm.$getVisibleNames( "manual.criterio_de_evaluacion", true, "Criterios de Evaluación" )))])],1),_c('b-tr',[_c('b-th',{staticClass:"text-center align-middle",attrs:{"rowspan":"2"}},[_vm._v("Cantidad de "+_vm._s(_vm.$getVisibleNames("manual.dimension", true, "Dimensiones")))]),_c('b-th',{staticClass:"text-center align-middle",attrs:{"rowspan":"2"}},[_vm._v("Ponderación")])],1)],1),(_vm.dimensions && _vm.dimensions.length > 0)?[_c('b-tbody',[_vm._l((_vm.cognitive_levels),function(cognitive_level){return _c('b-tr',{key:cognitive_level.id},[_c('b-td',[_c('div',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.v-secondary.noninteractive",value:(
                  cognitive_level.level + '. ' + cognitive_level.name
                ),expression:"\n                  cognitive_level.level + '. ' + cognitive_level.name\n                ",modifiers:{"v-secondary":true,"noninteractive":true}}],staticClass:"cognitive-level text-center align-middle"},[_vm._v(" "+_vm._s(cognitive_level.level)+" ")])]),_c('b-td',{staticClass:"text-center align-middle"},[_vm._v(_vm._s(_vm.getECPerCognitiveLevel(cognitive_level.level) && _vm.getECPerCognitiveLevel(cognitive_level.level).length > 0 ? _vm.getECPerCognitiveLevel(cognitive_level.level).length : "-"))]),_c('b-td',{staticClass:"text-center align-middle"},[_c('div',{class:{
                  'cl-dimensions-cell':
                    _vm.allows_crud &&
                    _vm.cognitiveLevelDimensions(cognitive_level.id).length,
                  'cl-dimensions-about': _vm.cognitiveLevelDimensions(
                    cognitive_level.id
                  ).length,
                },attrs:{"id":`cl-dimensions-cell-1-${cognitive_level.id}`}},[_vm._v(" "+_vm._s(_vm.cognitiveLevelDimensions(cognitive_level.id) && _vm.cognitiveLevelDimensions(cognitive_level.id).length > 0 ? _vm.cognitiveLevelDimensions(cognitive_level.id).length : "-")+" "),(_vm.cognitiveLevelDimensions(cognitive_level.id).length)?_c('b-popover',{attrs:{"target":`cl-dimensions-cell-1-${cognitive_level.id}`,"triggers":"click"},scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v(_vm._s(_vm.$getVisibleNames( "evaluations.observationachievement", true, "Dimensiones" )))]},proxy:true}],null,true)},[_vm._l((_vm.cognitiveLevelDimensions(
                      cognitive_level.id
                    )),function(dimension){return [(!isNaN(dimension.id))?_c('div',{key:dimension.id},[(_vm.allows_crud)?_c('div',{staticClass:"popover-dimension",attrs:{"id":`dimension-link-${dimension.id}`},on:{"click":function($event){return _vm.openDimensionForm(dimension.id, cognitive_level.id)}}},[_c('b-icon',{attrs:{"icon":"circle-fill","scale":"0.35"}}),_vm._v(" "+_vm._s(_vm.$getVisibleNames( "manual.dimension", false, "Dimensión" ) + " N°" + dimension.order)+" ")],1):_c('div',[_c('b-icon',{attrs:{"icon":"circle-fill","scale":"0.35"}}),_vm._v(" "+_vm._s(_vm.$getVisibleNames( "manual.dimension", false, "Dimensión" ) + " N°" + dimension.order)+" ")],1),(_vm.allows_crud)?_c('b-tooltip',{attrs:{"target":`dimension-link-${dimension.id}`,"variant":"secondary","placement":"right","delay":"100"}},[_c('b-icon',{staticClass:"mr-2",attrs:{"icon":"box-arrow-up-right"}}),_vm._v("Abrir formulario de "+_vm._s(_vm.$getVisibleNames( "manual.dimension", false, "Dimensión" ))+" ")],1):_vm._e(),_c('b-modal',{attrs:{"id":`edit-cl-dimension-${dimension.id}`,"title":'Editar ' +
                          _vm.$getVisibleNames(
                            'manual.dimension',
                            false,
                            'Dimensión'
                          ),"size":"xl","hide-footer":"","no-close-on-backdrop":""}},[_c('InstanceForm',{attrs:{"observation_instrument_id":dimension.observation_instrument,"ObservationInstance":dimension,"evaluation_id":_vm.evaluation_id},on:{"updated":function($event){return _vm.slotUpdatedDimension(dimension)}}})],1)],1):_c('div',{key:dimension.id},[_vm._v("N/A")])]})],2):_vm._e()],1)]),_c('b-td',{staticClass:"text-center align-middle"},[_vm._v(_vm._s(_vm.clDimensionWeighing(cognitive_level.id) ? _vm.clDimensionWeighing(cognitive_level.id) + "%" : "-"))])],1)})],2),_c('b-tfoot',{staticClass:"custom-footer"},[_c('b-tr',{staticClass:"text-center align-middle"},[_c('b-td',[_vm._v("Total ")]),_c('b-td',[_vm._v(_vm._s(_vm.evaluation_criterias() ? _vm.evaluation_criterias().filter((x) => _vm.verbInCognitiveLevelIds.includes(x.verb) ).length : "-"))]),_c('b-td',[_vm._v(" "+_vm._s(_vm.dimensions ? _vm.dimensions.filter((x) => _vm.verbInCognitiveLevelIds.includes(x.verb) ).length : "-"))]),_c('b-td',[_vm._v(_vm._s(_vm.clDimensionWeighingAmount() ? _vm.clDimensionWeighingAmount() + "%" : "-"))])],1)],1)]:[_c('b-td',{staticClass:"empty-instrument-alert",attrs:{"colspan":"8"}},[_c('b-icon',{attrs:{"icon":"exclamation-circle"}}),_vm._v(" El instrumento actual no tiene "+_vm._s(_vm.$getVisibleNames( "evaluations.observationachievement", true, "Dimensiones" ))+" creadas. ")],1)]],2)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }