<template>
  <div @click="closeAllPopovers()">
    <b-table-simple class="mt-3" bordered small foot-clone>
      <b-thead>
        <b-tr>
          <b-th rowspan="2" class="text-center align-middle"
            >Nivel Cognitivo</b-th
          >
          <b-th rowspan="2" class="text-center align-middle th-custom"
            >Cantidad de
            {{
              $getVisibleNames(
                "manual.criterio_de_evaluacion",
                true,
                "Criterios de Evaluación"
              )
            }}</b-th
          >
        </b-tr>
        <b-tr>
          <b-th rowspan="2" class="text-center align-middle"
            >Cantidad de
            {{
              $getVisibleNames("manual.dimension", true, "Dimensiones")
            }}</b-th
          >
          <b-th rowspan="2" class="text-center align-middle">Ponderación</b-th>
        </b-tr>
      </b-thead>
      <template v-if="dimensions && dimensions.length > 0">
        <b-tbody>
          <template>
            <b-tr
              v-for="cognitive_level in cognitive_levels"
              :key="cognitive_level.id"
            >
              <b-td
                ><div
                  class="cognitive-level text-center align-middle"
                  v-b-tooltip.v-secondary.noninteractive="
                    cognitive_level.level + '. ' + cognitive_level.name
                  "
                >
                  {{ cognitive_level.level }}
                </div>
              </b-td>
              <b-td class="text-center align-middle">{{
                getECPerCognitiveLevel(cognitive_level.level) &&
                getECPerCognitiveLevel(cognitive_level.level).length > 0
                  ? getECPerCognitiveLevel(cognitive_level.level).length
                  : "-"
              }}</b-td>
              <b-td class="text-center align-middle">
                <div
                  :id="`cl-dimensions-cell-1-${cognitive_level.id}`"
                  :class="{
                    'cl-dimensions-cell':
                      allows_crud &&
                      cognitiveLevelDimensions(cognitive_level.id).length,
                    'cl-dimensions-about': cognitiveLevelDimensions(
                      cognitive_level.id
                    ).length,
                  }"
                >
                  {{
                    cognitiveLevelDimensions(cognitive_level.id) &&
                    cognitiveLevelDimensions(cognitive_level.id).length > 0
                      ? cognitiveLevelDimensions(cognitive_level.id).length
                      : "-"
                  }}
                  <b-popover
                    v-if="cognitiveLevelDimensions(cognitive_level.id).length"
                    :target="`cl-dimensions-cell-1-${cognitive_level.id}`"
                    triggers="click"
                  >
                    <template #title>{{
                      $getVisibleNames(
                        "evaluations.observationachievement",
                        true,
                        "Dimensiones"
                      )
                    }}</template>
                    <template
                      v-for="dimension in cognitiveLevelDimensions(
                        cognitive_level.id
                      )"
                    >
                      <div :key="dimension.id" v-if="!isNaN(dimension.id)">
                        <div
                          v-if="allows_crud"
                          :id="`dimension-link-${dimension.id}`"
                          class="popover-dimension"
                          @click="
                            openDimensionForm(dimension.id, cognitive_level.id)
                          "
                        >
                          <b-icon icon="circle-fill" scale="0.35"></b-icon>
                          {{
                            $getVisibleNames(
                              "manual.dimension",
                              false,
                              "Dimensión"
                            ) +
                            " N°" +
                            dimension.order
                          }}
                        </div>
                        <div v-else>
                          <b-icon icon="circle-fill" scale="0.35"></b-icon>
                          {{
                            $getVisibleNames(
                              "manual.dimension",
                              false,
                              "Dimensión"
                            ) +
                            " N°" +
                            dimension.order
                          }}
                        </div>
                        <b-tooltip
                          v-if="allows_crud"
                          :target="`dimension-link-${dimension.id}`"
                          variant="secondary"
                          placement="right"
                          delay="100"
                        >
                          <b-icon
                            class="mr-2"
                            icon="box-arrow-up-right"
                          ></b-icon
                          >Abrir formulario de
                          {{
                            $getVisibleNames(
                              "manual.dimension",
                              false,
                              "Dimensión"
                            )
                          }}
                        </b-tooltip>

                        <b-modal
                          :id="`edit-cl-dimension-${dimension.id}`"
                          :title="
                            'Editar ' +
                            $getVisibleNames(
                              'manual.dimension',
                              false,
                              'Dimensión'
                            )
                          "
                          size="xl"
                          hide-footer
                          no-close-on-backdrop
                        >
                          <InstanceForm
                            :observation_instrument_id="
                              dimension.observation_instrument
                            "
                            :ObservationInstance="dimension"
                            :evaluation_id="evaluation_id"
                            @updated="slotUpdatedDimension(dimension)"
                          ></InstanceForm>
                        </b-modal>
                      </div>
                      <div v-else :key="dimension.id">N/A</div>
                    </template>
                  </b-popover>
                </div>
              </b-td>
              <b-td class="text-center align-middle">{{
                clDimensionWeighing(cognitive_level.id)
                  ? clDimensionWeighing(cognitive_level.id) + "%"
                  : "-"
              }}</b-td>
            </b-tr>
          </template>
        </b-tbody>

        <b-tfoot class="custom-footer">
          <b-tr class="text-center align-middle">
            <b-td>Total </b-td>
            <b-td>{{
              evaluation_criterias()
                ? evaluation_criterias().filter((x) =>
                    verbInCognitiveLevelIds.includes(x.verb)
                  ).length
                : "-"
            }}</b-td>
            <b-td>
              {{
                dimensions
                  ? dimensions.filter((x) =>
                      verbInCognitiveLevelIds.includes(x.verb)
                    ).length
                  : "-"
              }}</b-td
            >
            <b-td>{{
              clDimensionWeighingAmount()
                ? clDimensionWeighingAmount() + "%"
                : "-"
            }}</b-td>
          </b-tr>
        </b-tfoot>
      </template>
      <template v-else>
        <b-td colspan="8" class="empty-instrument-alert">
          <b-icon icon="exclamation-circle"></b-icon> El instrumento actual no
          tiene
          {{
            $getVisibleNames(
              "evaluations.observationachievement",
              true,
              "Dimensiones"
            )
          }}
          creadas.
        </b-td>
      </template>
    </b-table-simple>
  </div>
</template>
<script>
export default {
  name: "CognitiveLevelDimensionsST",
  components: {
    InstanceForm: () => import("../ObservationInstrument/InstanceForm.vue"),
  },
  props: {
    dimensions: { required: true },
    cognitive_levels: { required: true },
    evaluation_criterias: { required: true },
    evaluation_id: { required: true },
    observation_table: { type: Boolean, default: false },
    allows_crud: { type: Boolean, default: false },
  },
  data() {
    return {};
  },
  computed: {
    verbInCognitiveLevelIds() {
      let list = [];
      this.cognitive_levels.forEach((cl) => {
        list = [...new Set(list.concat(cl.verbs.map((x) => x.id)))];
      });
      return list;
    },
  },
  methods: {
    getECPerCognitiveLevel(cl_level) {
      let ec_verbs = [];
      this.evaluation_criterias().forEach((ec) => {
        ec_verbs.push(ec.verb);
      });
      let cl_ec_verbs = [];
      this.cognitive_levels.forEach((cl) => {
        if (cl.level == cl_level) {
          cl.verbs.forEach((cl_verb) => {
            let verb_match = ec_verbs.find((ec_verb) => cl_verb.id == ec_verb);
            if (verb_match) {
              cl_ec_verbs.push(cl_verb);
            }
          });
        }
      });
      return cl_ec_verbs;
    },
    cognitiveLevelDimensions(cl_id) {
      let cl_dimensions = [];
      let cl_verbs = [];
      this.cognitive_levels.forEach((cl) => {
        if (cl.id == cl_id) {
          cl.verbs.forEach((cl_verb) => {
            cl_verbs.push(cl_verb.id);
          });
        }
      });
      this.dimensions.forEach((dimension) => {
        if (cl_verbs.includes(dimension.verb)) {
          cl_dimensions.push(dimension);
        }
      });
      return cl_dimensions;
    },
    clDimensionWeighing(cl_id) {
      let weighing_sum = 0;
      this.cognitiveLevelDimensions(cl_id).forEach((dimension) => {
        weighing_sum = weighing_sum + dimension.weighing;
      });
      return weighing_sum;
    },
    clDimensionWeighingAmount() {
      let score_sum = 0;
      this.dimensions.forEach((dimension) => {
        score_sum = score_sum + dimension.weighing;
      });
      return score_sum;
    },
    slotUpdatedDimension(dimension) {
      this.$emit("updatedDimension", dimension);
    },
    openDimensionForm(dimension_id) {
      this.show_popover = true;
      this.$bvModal.show(`edit-cl-dimension-${dimension_id}`);
    },
    closeAllPopovers() {
      this.$root.$emit("bv::hide::popover");
    },
  },
};
</script>
<style scoped>
.specification-table-content {
  overflow: auto;
}

table {
  width: 100%;
}
th {
  background-color: var(--primary-color);
  color: var(--primary-font-color);
  font-size: var(--secondary-font-size);
  padding: 0 4px;
}
td {
  padding: 2px 4px;
}

tfoot {
  background-color: #eee;
}
.cognitive-level {
  text-decoration: underline;
}
.empty-instrument-alert {
  text-align: center;
  padding: 2em 0;
}
.th-custom {
  width: 25% !important;
}
.cl-dimensions-cell:hover {
  color: var(--kl-primary-button-color);
  text-decoration: underline;
  cursor: pointer;
}
.popover-dimension {
  cursor: pointer;
}
.popover-dimension:hover {
  color: var(--kl-primary-button-color);
  text-decoration: underline;
}
.cl-dimensions-about {
  text-decoration: underline;
  cursor: help;
}
</style>